<template>
  <div class="">
    <header-section
      class="promo--careers"
      :title="promo.title"
    />

    <div class="page">
      <div class="page__container container">
        <div class="page__content" v-html="description" />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue';

export default {
  name: 'PrivacyPolicy',
  components: {
    HeaderSection,
  },
  data() {
    return {
      promo: {
        title: 'Privacy Policy',
      },
      description: `
        <p>Privacy Policy text Beyond Identity is the most secure authentication platform in the market. An innovator in passwordless, Beyond Identity’s Passwordless Identity Platform uses real-time access controls to verify identities with devices, and checks granular risk signals for continuous authentication. Breaking down the barriers between cybersecurity, identity, and device management, Beyond Identity fundamentally changes the way the world logs in, enabling teams to continue to work in this new zero trust world. Enterprises and customers turn to Beyond Identity to protect their data and user privacy, and to defend against security and compliance risks. </p>
        <p>We are currently looking for a digital designer to spearhead the company's design. This person will center their design decisions around brand and business goals and will be a team player, working closely with the marketing team and web developer to create great work. This role will be based in our New York office (when we are back in the office).</p>
        <h3>Responsibilities:</h3>
        <ul>
        <li>Produce compelling creative across digital properties that increases awareness in the market and drives brand growth.</li>
        <li>Own website design, landing pages, email graphics, social graphics and digital related campaign design.</li>
        <li>Work with our web developer to deliver easy to navigate experiences for our corporate and localized websites.</li>
        <li>Collaborate with internal product designers and external creative functions such as illustrators, photographers, and videographers to execute industry leading content.</li>
        <li>Ensure our designs adhere to our brand guidelines and maintain consistency throughout all touchpoints in the marketing funnel.</li>
        <li>Collaborate with product and digital marketing managers to tell a compelling, dynamic story through copy and design.</li>
        <li>Prepare graphically engaging presentations for events and executive presentations.</li>
        <li>Support the Marketing team on strategic design briefs for digital, editorial, social media, motion, branded or commercial, by ensuring consistency in look and feel, accuracy, functionality and brand identity.</li>
        </ul>
        <h3>Requirements:</h3>
        <ul>
        <li>5-8+ years of relevant design experience with a portfolio that demonstrates this.</li>
        <li>Previous experience working in a technical environment, within B2B or at a start-up, is preferred.</li>
        <li>Understanding of technical, production and creative constraints, and ability to communicate effective solutions.</li>
        <li>Proficiency in scaling brand identity across systems and an evolving media landscape.</li>
        <li>Excellent communicator and presenter of design proposals and works in progress with excellent ability to absorb and field design critique.</li>
        <li>Process-driven designer who can effectively manage expectations and communication with stakeholders.</li>
        <li>Proficiency in digital design skills and knowledge of Illustrator, Photoshop, Indesign, typography, compage, and photography.</li>
        <li>Ability to manage multiple jobs and meet deadlines.</li>
        <li>Additional skills and knowledge of Figma, After Effects, video editing, and/or illustration are a bonus.</li>
        </ul>
      `,
    };
  },
};
</script>
